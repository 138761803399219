import { Draggable, Sortable, Plugins } from "@shopify/draggable";
import MicroModal from 'micromodal';
import Cookies from 'js-cookie';
import { hideSidebar } from 'helpers.js';

var radioStateReleaseYear;
var radioStatePlayedYear;

function getFiltersURL(data){
    let filters_str = "";
    let filters = {};

    $.each(data, function(index, value){
        if(value.value.length == 0) { return; }
        
        const prefix = "filters[";
        let key = value.name.substring(prefix.length, value.name.length);
        const end_index = key.search(']');
        key = key.substring(0, end_index).toLowerCase();

        if(key.length == 0) { return; }

        const defAttr = $('input[name="' + value.name + '"][value="' + value.value + '"]').attr('default');

        if (typeof defAttr !== typeof undefined && defAttr !== false) {
            return;
        }

        if(key in filters) {
            filters[key].push(value.value.toLowerCase());
        } else {
            filters[key] = [value.value.toLowerCase()];
        }
    });

    $.each(filters, function(key, value){
        filters_str += key + ":" + value.join(',') + ";"
    });

    return filters_str.slice(0, -1);
}

function updateBorderColor(e){
    if (e.value.length > 0) {
        e.style = "background-color: var(--back-field-highlight);";
    } else {
        e.style = "";
    }
}

function getPosition(string, subString, index) {
  return string.split(subString, index).join(subString).length;
}

let sortings = [];
let resetWidth = true;
let collections = {};
let curr_collection_id;

onmount('.filter-toggable-checkbox', function(){
    const filter_name = "gl_filter_" + $(this).attr('id');

    $(this).prop('checked', Cookies.get(filter_name));

    $(this).on('change', function() {
      if(!$(this).is(':checked')) {
        Cookies.remove(filter_name);
      } else {
        Cookies.set(filter_name, $(this).is(':checked'), { sameSite: 'strict', expires: 365 });
      }
      window.location.reload(false);
    });
});

onmount('#no_platforms_logged', function(){
    $(this).on('change', function(){
       $('#plat-played-on').prop('disabled', $(this).is(':checked'));
       $('#plat-played-on').selectpicker('refresh');
    });
});

onmount('#dismiss, .sidebar-overlay', function() {
    $(this).on('click', function() {
        hideSidebar();
    });
});

onmount('#sidebarCollapse', function() {
    $(this).on('click', function() {
         // open sidebar
        $('#filter-sidebar').addClass('active');
        // fade in the overlay
        $('.sidebar-overlay').addClass('active');
        $('body').addClass('noScroll');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    });
});

onmount('#folderSidebarCollapse', function() {
    $(this).on('click', function() {
         // open sidebar
        $('#folder-sidebar').addClass('active');
        // fade in the overlay
        $('.sidebar-overlay').addClass('active');
        $('body').addClass('noScroll');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    });
});

onmount('#settingsSidebarCollapse', function() {
    $(this).on('click', function() {
         // open sidebar
        $('#settings-sidebar').addClass('active');
        // fade in the overlay
        $('.sidebar-overlay').addClass('active');
        $('body').addClass('noScroll');
        $('.collapse.in').toggleClass('in');
        $('a[aria-expanded=true]').attr('aria-expanded', 'false');
    });
});

function getNthIndex(str, find, n) {
    let lastIndex = 0;
    while(n > 0) {
        lastIndex = str.indexOf(find, lastIndex) + 1;
        n -= 1;
    }

    return lastIndex;
}

onmount('#filters-submit', function(){
    $(this).on('click', function(){
        const filters_url = getFiltersURL($('#filter_form').serializeArray());
        const path = $(this).attr('org_url_sort');
        let user_path;

        if (!path.endsWith("/")) {
            user_path = path + "/";
        } else {
            user_path = path;
        }

        window.location.href = user_path + filters_url + (filters_url.length > 0 ? "/" : "");
    });
});

onmount('#new-collection', function(){
    $(this).on('click', function(){
        $.ajax({
            type: 'POST',
            url: '/new-collection/',
            data: {filters_url: getFiltersURL($('#filter_form').serializeArray()) }
        });
    });
})

onmount('#mastered-filter-options input', function(){
    $(this).on('click', function(){
        let option = $(this);
        if (option.attr('waschecked') == "true") {
            option.prop('checked', false);
            option.attr('waschecked', false);
        } else {
            option.attr('waschecked', true);
        }
        
        option.siblings('input').attr('waschecked', false);
    });
});

onmount('input[name="filters[release_year]"]', function(){
    $(this).on('click', function(){
        if (radioStateReleaseYear === this) {
            this.checked = false;
            radioStateReleaseYear = null;
            var cust = $('input[name="filters[release_year_custom]"]')[0];

            if (cust.value.length > 0) {
                cust.style = "border-color: var(--back-pink-dk);";
            }
        } else {
            radioStateReleaseYear = this;
            $('input[name="filters[release_year_custom]"]')[0].style = ""
        }
    })
});

onmount('input[name="filters[release_year_custom]"]', function(){
    $(this).on('click', function(){
        $('input[name="filters[release_year]"]').prop('checked', false);
    });

    $(this).on('focusout', function(){
        updateBorderColor(this);
    });

    $(this).on('change', function(){
        updateBorderColor(this);
    });
});

$(document).on('turbolinks:load', function() {
    $('input[name="filters[played_year]"]').click(function(){
        if (radioStatePlayedYear === this) {
            this.checked = false;
            radioStatePlayedYear = null;
            var cust = $('input[name="filters[played_year_custom]"]')[0];

            if (cust.value.length > 0) {
            	cust.style = "border-color: var(--back-pink-dk);";
			}
        } else {
            radioStatePlayedYear = this;
            $('input[name="filters[played_year_custom]"]')[0].style = ""
        }
    });

    $('input[name="filters[played_year_custom]"]').click(function(){
    	$('input[name="filters[played_year]"]').prop('checked', false);
    });

    $('input[name="filters[played_year_custom]"]').focusout(function(){updateBorderColor(this);});
    $('input[name="filters[played_year_custom]"]').change(function(){updateBorderColor(this);});
});