onmount('#comment-submit', function(){
	$(this).on('click', function(){
		const model_id = $(this).attr('model_id');
		const model_type = $(this).attr('model_type');
		const comment_body = $('#comment_body').val();

		$(this).prop('disabled', true);
    	$(this).html('Sending...');

		$.ajax({
			type: 'POST',
			url: '/comment/' + model_type + '/new/' + model_id,
			data: { comment_body: comment_body },
			success: function(res) {
				$('#comment-submit').prop('disabled', false);
    			$('#comment-submit').html('Comment');
    			$('#comment_body').val('');
				toastr.success('Your comment has been posted');
			}
		});
	});
});

onmount('.delete-comment-btn', function() {
    $(this).on('click', function() {
        $(this).prop("disabled", true);
        let res = confirm("Are you sure you'd like to delete this comment?");
        if (res == true) {
            const comment_id = $(this).attr('comment_id');
            $.ajax({
                type: "DELETE",
                url: "/comment/destroy/" + comment_id,
                success(res){
                	$('#comment-' + comment_id).remove();
                	toastr.success('Comment removed');
                }
            });
        } else {
            $(this).prop("disabled", false);
        }
    });
});

onmount('#display-removed-comments', function(){
	$(this).on('click', function(){
		$(this).addClass('d-none');
		$('.comment-hidden').removeClass('d-none');
	});
});

onmount('#appeal-comment-submit', function(){
	$(this).on('click', function(){
		const review_id = $(this).attr('review_id');
		const comment_body = $('#appeal_comment_body').val();
		const from_mod = $(this).attr('from_mod');

		$(this).prop('disabled', true);
    	$(this).html('Sending...');

		$.ajax({
			type: 'POST',
			url: '/comment/appeal/new/' + review_id,
			data: { comment_body: comment_body, from_mod: from_mod },
			success: function(res) {
				$('#appeal-comment-submit').prop('disabled', false);
    			$('#appeal-comment-submit').html('Comment');
    			$('#appeal-comment-submit').val('');
				toastr.success('Your comment has been submitted');
				window.location.reload();
			}
		});
	});
});